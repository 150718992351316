.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-center {
  text-align: center;
}

.container-preview {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
}

.container-preview > div {
  width: 100%;
}

.alert-icon {
  font-size: 3em;
  margin-bottom: 1em;
  color: #e0dfdf;
}

.align-col-button {
  display: flex;
  align-items: center;
  margin-top: 6px;
}

.align-col-button2 {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}

@primary-color: #5a4f0a;