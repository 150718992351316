.container-botoes{
    display: flex;
    justify-content: end;
}
.ant-picker{
    width: 100% !important;
}

.button-space{
    margin: 1em 0;
}

.uppercase-datepicker{
    text-transform: uppercase;
}

.content-list .ant-form-item{
    margin-bottom: 1em !important;
}