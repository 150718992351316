@import "node_modules/gp-theme/dist/scss/styles.scss";

.painel-erro {
  align-items: center;
  min-height: calc(100% - #{$topo});
  i {
    color: $theme-inactive;
  }
  .conteudo {
    padding: 4rem 2rem;
  }

  .title-erro {
    color: $theme-inactive;
    font-weight: bold;
  }

  hr {
    background: $theme-inactive;
  }
}
