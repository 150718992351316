.iframe-container {
  display: flex;
  justify-content: center; /* Centraliza o conteúdo horizontalmente */
  align-items: flex-start; /* Alinha os itens no topo da div */
  width: 100%; /* A div ocupa toda a largura disponível do Card */
  height: 800px; /* Aumenta a altura da div (ajuste conforme necessário) */
  padding: 20px; /* Adiciona espaço ao redor do iframe */
  box-sizing: border-box;
}

.titulo-gerencial {
  text-align: center; /* Centraliza o título */
  margin: 20px 0; /* Adiciona espaço acima e abaixo do título */
  color: rgb(5, 5, 5); /* Cor do título */
  font-size: 24px; /* Ajuste o tamanho da fonte conforme necessário */
}

.iframe-container iframe {
  max-width: 100%; /* Permite que o iframe se ajuste à largura da div */
  max-height: 100%; /* Permite que o iframe se ajuste à altura da div */
  width: 100%; /* Ajusta a largura dinamicamente */
  height: 100%; /* Ajusta a altura dinamicamente */
  background-color: #0a3b03; /* Cor de fundo escura */
}
