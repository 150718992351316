@import "~antd/dist/antd.less";

@import (css)
  url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&display=swap");

h1 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;

  @media (max-width: 750px) {
    font-size: 2rem;
  }
}

.ant-pro-page-container-children-content {
  .ant-card {
    margin-bottom: 20px;
  }
}

.ant-pro-grid-content {
  margin-bottom: 20px;
}

.select .ant-col {
  box-shadow: rgb(18 72 26 / 50%) 0px 1px 2px 0px,
    rgb(18 72 26 / 40%) 0px 0px 5px 2px;
}

.card-selected {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(18, 72, 26) 0px 0px 5px 3px !important;
}

//PAGE-HEADER >>>>>>>>>>>>>>>>>>>>>>>>>>

.ant-page-header {
  box-sizing: border-box;
  height: 75px;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  position: relative;
  padding: 0px 24px;
  background-color: #f0f2f5;
}

.ant-page-header-heading-title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 20px;
  height: 75px;
  display: flex;
  align-items: center;
  color: #000000;
}

//INPUTS>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

// .ant-form-item-label > label {
//   font-family: Open Sans;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 14px;
//   line-height: 24px;
//   display: flex;
//   align-items: center;
//   color: #000000;
// }

// .ant-input {
//   box-sizing: border-box;
//   height: 39px;
//   margin: 0;
//   padding: 0;
//   font-variant: tabular-nums;
//   list-style: none;
//   font-feature-settings: "tnum", "tnum";
//   position: relative;
//   display: inline-block;
//   width: 100%;
//   min-width: 0;
//   padding: 4px 11px;
//   font-size: 14px;
//   color: rgba(0, 0, 0, 0.85);
//   font-size: 16px;
//   line-height: 1.5715;
//   background-color: #fff;
//   background-image: none;
//   border: 1px solid #ced4da;
//   border-radius: 2px;
//   transition: all 0.3s;
// }

// .ant-input[disabled] {
//   color: #000000;
//   height: 39px;
//   background: #ebebeb;
//   border: 1px solid #ced4da;
//   box-sizing: border-box;
//   border-radius: 4px;
//   padding: 4px 11px;
//   font-size: 14px;
// }

// //DATEPICKER >>>>>>>>>>>>>>>>>>>>>>>>>>>

.ant-picker {
  height: 33px !important;
}

// .ant-picker {
//   box-sizing: border-box;
//   margin: 0;
//   padding: 0;
//   color: #0000;
//   font-variant: tabular-nums;
//   line-height: 1.5715;
//   list-style: none;
//   font-feature-settings: "tnum", "tnum";
//   padding: 2.5px 11px 2.5px;
//   position: relative;
//   display: inline-flex;
//   align-items: center;
//   background: #fff;
//   border-radius: 2 px;
//   transition: border 0.3s, box-shadow 0.3s;
//   padding: 10px;
//   width: 100%;
//   height: 42px;
// }

// .ant-picker.ant-picker-disabled {
//   color: #000;
//   height: 39px;
//   background: #ebebeb;
//   border: 1px solid #ced4da;
//   box-sizing: border-box;
//   border-radius: 4px;
//   width: 100%;
// }

// .ant-picker-input > input[disabled] {
//   color: #000;
//   font-size: 14px;
// }

// .ant-picker-input > input {
//   color: #000;
//   font-size: 14px;
// }

// .ant-picker-suffix {
//   color: #757575;
//   font-size: 30px;
// }

// .ant-picker.ant-picker-disabled .ant-picker-suffix {
//   color: #757575;
//   font-size: 30px;
// }

// //SELECT >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

// .ant-select {
//   color: #000000;
//   height: 39px;
//   background: #fff;
//   border: 1px solid #ced4da;
//   box-sizing: border-box;
//   border-radius: 4px;
//   font-size: 14px;
// }

// .ant-select:hover {
//   border: 1px solid #12481a;
// }

// .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
// .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
//   padding-right: 20px;
//   margin-top: 5px;
// }

// .ant-select-disabled.ant-select:not(.ant-select-customize-input)
//   .ant-select-selector {
//   color: #000;
//   height: 100%;
//   background: #ebebeb;
//   cursor: not-allowed;
//   border: none !important;
// }

// .ant-select:not(.ant-select-customize-input) .ant-select-selector {
//   position: relative;
//   background-color: #fff;
//   border: none;
//   border-radius: 2px;
//   transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
// }

// .ant-select-disabled.ant-select:not(.ant-select-customize-input)
//   .ant-select-selector {
//   color: #000;
//   background: #ebebeb;
//   cursor: not-allowed;
//   border: none !important;
// }

// .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
//   .ant-select-selector {
//   box-shadow: none !important;
// }

//RADIO >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

.ant-radio-disabled + span {
  color: #000000;
  cursor: not-allowed;
}

.ant-radio-disabled .ant-radio-inner::after {
  background: #757575;
  border-color: #757575;
}

.ant-radio-disabled .ant-radio-inner {
  border-color: #757575 !important;
  cursor: not-allowed;
}

.ant-radio-button-wrapper:first-child:last-child {
  border-radius: 5px;
}
//CARD >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

.ant-card-bordered {
  background: #ffffff;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  border-radius: 5px;
}

.ant-card-head {
  border-bottom: none;
}

.ant-card-body {
  padding: 6px;
}

.ant-card-head-title {
  font-size: 1.6rem !important;
  color: #212529 !important;
  border-bottom: 1px #ddd solid !important;
  margin: 0;
}

.ant-descriptions-item-label {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.6);
  mix-blend-mode: normal;
}

.ant-descriptions-item-content {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: #000000;
  mix-blend-mode: normal;
}

.ant-descriptions-item-label::after {
  content: ":";
  position: relative;
  top: -0.5px;
  margin: 0px;
}

//FILTRO >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

.filtro .ant-col.ant-col-6:last-child {
  padding-top: 22px;
}

//BUTTON >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

.ant-btn {
  //box-shadow: 0px 5px 8px rgba(24, 24, 24, 0.18);
}

.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 8px;
  margin-bottom: 2px;
}

.ant-input-search-button {
  height: 39px;
}

//TAG >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

.ant-tag {
  //box-shadow: 0px 5px 8px rgba(24, 24, 24, 0.18);
}

//TABLE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

.ant-table-column-title {
  text-align: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #495057;
  background-color: #e9ecef;
}

.ant-table-tbody > tr > td {
  text-align: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  padding: 8px;
  line-height: 24px;
  border: 1px solid #dee2e6;
}

.ant-table-thead > tr > th {
  text-align: center;
  padding: 10px;
  height: 65px;
  background-color: #e9ecef;
}
.ant-table-column-sorters::after {
  border: 1px solid #dee2e6;
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: #e9ecef;
}

// .ant-table-cell {
//   font-size: 14px;
// }

//PAGINATION >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

// .ant-pagination-item {
//   background-color: #fff;
//   border: 1px solid #12481a;
// }

// .ant-pagination-item-active a {
//   color: #fff;
//   background-color: #12481a;
// }

// .ant-pagination-item a:hover {
//   color: #fff;
// }

// .ant-table-pagination > * {
//   flex: none;
//   margin: 0px;

//   :hover {
//     background: #12481a;
//   }
// }

// .ant-pagination-prev .ant-pagination-item-link,
// .ant-pagination-next .ant-pagination-item-link {
//   display: block;
//   width: 100%;
//   height: 100%;
//   padding: 0;
//   font-size: 12px;
//   text-align: center;
//   background-color: #fff;
//   border: 1px solid #12481a;
//   border-radius: 2px;
//   outline: none;
//   transition: all 0.3s;
//   color: #12481a;
// }

// .ant-pagination-next .ant-pagination-item-link:hover {
//   background-color: #12481a;
//   color: #ffff;
// }

// .ant-pagination-prev .ant-pagination-item-link:hover {
//   background-color: #12481a;
//   color: #ffff;
// }

// .ant-pagination-prev .ant-pagination-item-link:hover {
//   background-color: #12481a;
//   color: #ffff;
// }

// .ant-pagination-jump-prev
//   .ant-pagination-item-container
//   .ant-pagination-item-ellipsis,
// .ant-pagination-jump-next
//   .ant-pagination-item-container
//   .ant-pagination-item-ellipsis {
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   display: block;
//   margin: auto;
//   background-color: #fff;
//   border: 1px solid #12481a;
//   font-family: Arial, Helvetica, sans-serif;
//   letter-spacing: 2px;
//   text-align: center;
//   text-indent: 0.13em;
//   opacity: 1;
//   transition: all 0.2s;
//   color: #12481a;
// }

// .ant-pagination-jump-prev
//   .ant-pagination-item-container
//   .ant-pagination-item-ellipsis:hover,
// .ant-pagination-jump-next
//   .ant-pagination-item-container
//   .ant-pagination-item-ellipsis:hover {
//   background-color: #12481a;
//   color: #fff;
// }

// MENU-SIDE-BAR >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

.ant-menu-item .ant-menu-item-icon + span,
.ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
  margin-left: 10px;
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s,
    color 0.3s;
  font-size: 16px;
}

.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  min-width: 14px;
  font-size: 16px;
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1) 0s,
    margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s, color 0.3s ease 0s;
}

//STEPS >>>>>>>>>>>>>>>>>>>>>>>>>>>

.ant-steps-container {
  display: flex;
  flex-direction: column;
}

.ant-steps-label-vertical .ant-steps-item-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 42px;
  width: 50px;
  height: 50px;
  background: #dddddd;
  border: 1px solid #12481a;
  box-sizing: border-box;
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #12481a;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #12481a;
  height: 50px;
  width: 50px;
  /* text-align: center; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-icon
  .ant-steps-icon {
  /* color: #fff; */
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.ant-steps-item-tail {
  position: absolute;
  top: 21px;
  left: 10px;
  width: 100%;
  padding: 0 10px;
}
//SKELETON >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

.ant-skeleton.ant-skeleton-active .ant-skeleton-input {
  position: absolute;
  width: 100%;
}

//TOAST>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.

.ant-notification-notice {
  margin-top: 50px;
}

//ReactPanZoom>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.

.pan-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-height: 650px;
  min-width: 40vw;
  min-height: 500px;
  box-shadow: rgb(53 67 93 / 32%) 0px 2px 6px;
  border-radius: 5px;
}

@primary-color: #5a4f0a;