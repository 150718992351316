@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap");

@import "node_modules/gp-theme/dist/scss/styles.scss";

body {
  font-family: "Open Sans", sans-serif;
}

html {
  overflow-x: hidden;
}

.legend {
  font-size: 1.6rem !important;
  color: #212529 !important;
  margin: 0;
  padding-bottom: 15px;
  margin-top: 3rem;
}

.linha-legenda {
  border-bottom: 1px $white1 solid !important;
  margin-left: -20px !important;
  margin-bottom: 2rem;
  margin-right: -1.4rem;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--theme-darker) !important;
  background-color: var(--theme-darker) !important;
}

.custom-checkbox,
.custom-control-label {
  cursor: pointer;
}

.custom-control-input[checked][disabled] ~ .custom-control-label::before,
.custom-control-input:checked:disabled ~ .custom-control-label::before {
  border-color: $gray !important;
  background-color: $gray !important;
  cursor: not-allowed;
}

.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
  cursor: not-allowed;
}

.btn-primary {
  background: $primary-light !important;
  color: $primary !important;
  border-color: $primary-light !important;

  i {
    margin-right: 13px;
  }

  &:hover {
    background-color: $primary !important;
    color: $white !important;
  }
}

.btn-outline-primary {
  color: $primary !important;
  border: 1px solid $primary !important;

  &:hover:not(:disabled) {
    color: $white !important;
    background-color: $primary !important;
  }

  &:disabled {
    cursor: not-allowed;
  }

  i {
    margin-right: 13px;
  }
}

.btn-outline-dark {
  i {
    margin-right: 13px;
  }
}

.btn-outline-success {
  color: var(--theme-darker) !important;
  border: 1px solid var(--theme-darker) !important;

  &:hover:not(:disabled) {
    color: $white !important;
    background-color: var(--theme-darker) !important;
  }

  &:disabled {
    cursor: not-allowed;
  }

  i {
    margin-right: 13px;
  }
}

.btn-group-vertical {
  display: flex;
  flex-direction: column;
  align-self: baseline;
  margin-top: 2.4rem;
}

.btn-warning {
  background: $warning-light !important;
  color: $warning-dark !important;
  border: 1px solid $warning-light !important;

  i {
    margin-right: 13px;
  }

  &:hover {
    background-color: $warning-dark !important;
    color: $warning-light !important;
  }
}

.btn-success {
  background: $sucess-light !important;
  color: var(--theme-darker) !important;
  border: 1px solid $sucess-light !important;

  i {
    margin-right: 13px;
  }

  &:hover {
    background-color: var(--theme-darker) !important;
    color: $white !important;
  }
}

.btn-info {
  background: var(--theme-darker) !important;
  border: 1px solid var(--theme-darker) !important;

  &:hover:not(:disabled) {
    background-color: $theme-dark !important;
    border: 1px solid $theme-dark !important;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem #12481a82 !important;
  }

  i {
    margin-right: 13px;
  }
}

.btn-icon-info {
  background: var(--theme-darker) !important;
  border: 1px solid var(--theme-darker) !important;
  color: $white !important;

  &:hover:not(:disabled) {
    background-color: $theme-dark !important;
    border: 1px solid $theme-dark !important;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem #12481a82 !important;
  }
}

.btn-danger {
  background-color: $danger !important;
  border: 1px solid $danger !important;
  // background-color: $danger !important;
  // border: 1px solid $danger !important;
}

.btn-vertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1.2rem;
}

.badge {
  padding: 5px 2rem !important;
  font-size: 100% !important;
}

.badge-success {
  background: $green-light !important;
  color: $black !important;
  border: 1px solid $sucess-light !important;
}

.badge-warning {
  background: $yellow-light !important;
  color: $black !important;
  border: 1px solid $warning !important;
}

.badge-danger {
  background: $red-light !important;
  color: $black !important;
}

.badge-secondary {
  background: $gray-light !important;
  color: $black !important;
}

.button-right {
  justify-content: flex-end;
}

.margin-top {
  display: flex;
  flex-direction: column;
  align-self: baseline;
  margin-top: 2.4rem;
}

.btn-group-right {
  display: flex;
  flex-direction: row;
  align-self: baseline;
}

.btn-novo {
  text-align: right;
}

.form-filtro-tela {
  align-items: baseline !important;
}

.modal-footer {
  padding: 0.75rem 0 0 0 !important;
}

form {
  .form-steps {
    padding-right: 20px;
    padding-left: 10px;
    padding-top: 40px;

    legend {
      font-weight: 700;
      font-size: 1.1rem;
      color: $gray;
      border-bottom: 1px $gray solid;
      margin: 0;
    }

    .form-row {
      margin: 0;
      //background: #f8f8f8;
      padding: 10px 0 0;

      label {
        font-weight: 600;
        font-size: 0.9rem;
        color: #444;
        margin-bottom: 3px;
      }
    }
  }

  .form-check input,
  .form-check label {
    margin-right: 10px;
  }

  .form-check .radio {
    float: left;
  }

  .form-group,
  .form-steps,
  .form-row {
    clear: both;
  }
}

.content {
  overflow-y: auto;
}

.acoes {
  button {
    margin-left: 10px;
  }
}

.painel-error-container {
  background-color: $base-color;
  min-height: 100vh;

  .conteudo-error {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 100vh;
  }

  .painel-erro {
    align-items: center;
    min-height: calc(100% - #{$topo});
    i {
      color: $theme-inactive;
    }
    .conteudo {
      padding: 4rem 2rem;
    }

    .title-erro {
      color: $theme-inactive;
      font-weight: bold;
    }

    hr {
      background: $theme-inactive;
    }

    .btn-error {
      display: flex;
      justify-content: center;
    }
  }
}

.form-group.required .col-form-label:after {
  content: " *";
  color: red;
}

.input-file {
  position: relative;
  display: inline-block;
  cursor: pointer;
  height: 1.5rem;

  input {
    margin: 0;
    filter: alpha(opacity=0);
    opacity: 0;
  }
}

.input-file-certidao {
  cursor: pointer;
  height: 1.5rem;
  background: #12481a;
  color: white;
  padding: 5px;
  border-radius: 3px;
}

.input-file-custom {
  text-align: left;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: calc(1.5em + 0.5rem + 2px);
  color: #495057;
  background-color: $gray1;
  border: 1px solid $gray3;
  box-shadow: inset 0 0.5px 0.5px rgba(0, 0, 0, 0.05);
  user-select: none;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;

  &:before {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    z-index: 6;
    display: block;
    content: "Selecione";
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    line-height: 1.5;
    font-weight: 400;
    color: $white;
    background-color: var(--theme-darker);
    border: 1px solid var(--theme-darker);
    border-radius: 0 3px 3px 0;
  }

  &.is-download:before {
    content: "Download" !important;
  }

  &.is-invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: #e9ecef !important;
  color: #495057 !important;
  opacity: 1;
}

.ant-btn > span {
  vertical-align: middle;
}

.anticon svg {
  vertical-align: baseline;
}

.ant-btn-dangerous.ant-btn-primary {
  background: #900000 !important;
  border: #900000 !important;
}

.ant-upload-list-item {
  margin: 0 !important;
}

.ant-radio-disabled + span {
  color: #495057 !important;
}

.ant-input[disabled] {
  background-color: #e9ecef !important;
  color: #495057 !important;
}

.ant-input[readonly] {
  background-color: #e9ecef !important;
  color: #495057 !important;
  border: 1px solid #d9d9d9 !important;
  outline: none !important;
  cursor: not-allowed !important;
  box-shadow: unset !important;
}

.ant-picker.ant-picker-disabled {
  background-color: #e9ecef !important;
}

.ant-picker-input > input[disabled] {
  color: #495057 !important;
}

.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  height: 32px !important;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  height: 32px !important;
  width: 32px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  span {
    font-size: 16px !important;
  }
}

.ant-message {
  z-index: 10000 !important;
}


